import React from "react";

const Contact = () => {
  return (
    <>
      <section className="page-banner-section">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="page-banner-title">
                        <h1 className="title">Contact Us</h1>
                    </div>
                </div>
            </div>
        </div>
      </section>

      <section className="contact-information">

      <div className="container">
        <div className="row">
            <div className="col-lg-4">
                <div className="contact-title">
                    <span className="sub-title">Get excited already?</span>
                    <h2 className="title">Reach me!</h2>
                </div>

                <div className="contact-info-wrap max-mb-n50">
                    <div className="contact-info max-mb-50">
                        <div className="icon">
                            <i className="fa fa-map-marker"></i>
                        </div>
                        <div className="info">
                            <h4 className="title"> Address</h4>
                            <span className="info-text"> 1800 Abbot Kinney Blvd. Unit D &amp; E Venice</span>
                        </div>
                    </div>

                    <div className="contact-info max-mb-50">
                        <div className="icon">
                            <i className="fa fa-phone"></i>
                        </div>
                        <div className="info">
                            <h4 className="title"> Contact</h4>
                            <span className="info-text"> Mobile: <strong>(+91) - 1234 - 567 - 890</strong> 
                                <br/> 
                                Tollfree: <strong>1800 – 1102 -1000</strong>
                                <br/>
                                Mail: <a href="mailto:hello@maxcoach.com">hello@maxcoach.com</a>
                            </span>
                        </div>
                    </div>

                    <div className="contact-info max-mb-50">
                        <div className="icon">
                            <i className="fa fa-clock-o"></i>
                        </div>
                        <div className="info">
                            <h4 className="title"> Hour of operation</h4>
                            <span className="info-text"> Monday – Friday : 09:00 – 6:00 <br/> Sunday &amp; Saturday: 10:30 – 22:00</span>
                        </div>
                    </div>

                </div>
            </div>
            
            <div className="col-lg-8">
                      <div class="contact-form pl-90 pl-md-0 pl-sm-0 pl-xs-0">
                        <form action="" id="contact-form" method="post">
                          <div class="row max-mb-n30">
                            <div class="col-md-6 col-12 max-mb-30">
                              <input type="text" placeholder="Your Name *" name="name" />
                            </div>
                            <div class="col-md-6 col-12 max-mb-30">
                              <input type="email" placeholder="Email *" name="email" />
                            </div>
                            <div class="col-md-12 col-12 max-mb-30">
                              <input type="text" placeholder="Subject *" name="subject" />
                            </div>
                            <div class="col-12 max-mb-30">
                              <textarea name="message" placeholder="Message"></textarea>
                            </div>
                            <div class="col-12 text-left max-mb-30">
                              <button class="btn btn-primary btn-hover-secondary btn-width-180 btn-height-60">Submit</button>
                            </div>
                          </div>
                        </form>
                        <p class="form-messege"></p>
                      </div>
                    </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Contact;
