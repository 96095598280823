import React from "react";
import { NavLink } from "react-router-dom";
import BlogCard from "./components/BlogCard";
import BlogData from "./components/BlogData";
import Hcarddata from "./components/Hcarddata";
import Healthcard from "./components/Healthcard";
import ServiceCard from "./components/ServiceCard";
import ServiceData from "./components/ServiceData";

const Home = () => {
  return (
    <>
      <div className="banner">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="banner-content">
                <h6>Change Your Life</h6>
                <h1>Health Coaching<br/> 
                Just Got <span>Easier!</span></h1>
                <NavLink to="JavaScript:Void(0);" class="btn btn-primary-two btn-hover-secondary"> Get started today </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section id="life-style">
        <div className="container">
          <div class="section-title text-center">
            <span class="sub-title">Together We Can Create</span>
            <h2 class="title">Inspring you to live a <span>healthier</span> lifestyle.</h2>
          </div>
          <div className="row health-block">
            {
                Hcarddata.map((val, ind) => {
                    return <Healthcard 
                    imgsrc={val.imgsrc}
                    title={val.title}
                    desc={val.desc}
                    />
                })
            }
          </div>
          <div className="row mt-5">
            <div className="col text-center">
              <NavLink to="" className="btn btn-off-white btn-hover-secondary mx-3">Learn more </NavLink>
              <NavLink to="" className="btn btn-primary-two btn-hover-secondary mx-3"> Get started today </NavLink>
            </div>
          </div>
        </div>
      </section>

      <section className="services">
            <div className="container">
                <div className="section-title text-center">
                    <span className="sub-title">Do not wait for Tomorrow!</span>
                    <h2 className="title playfair-font">Health Coaches look at <span>exercise, eating, wellness,</span> <br/> and <span>food</span> not just as calories and weight loss, but <br/> also on mental, physical, and spiritual terms.</h2>
                </div>

                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <div className="row max-mb-n30">
                            {
                                ServiceData.map((val, ind) => {
                                    return <ServiceCard
                                        imgsrc={val.imgsrc}
                                        title={val.title}
                                        desc1={val.desc1}
                                        desc2={val.desc2}
                                        desc3={val.desc3}
                                    />
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="event-section">
            <div class="container">

                <div class="section-title text-center">
                    <span class="sub-title">ARTICLES & TIPS</span>
                    <h2 class="title playfair-font">Latest from the  <span>blog</span></h2>
                </div>

                <div class="row mt-5">
                    {
                      BlogData.map((val, ind) => {
                        return<BlogCard
                            imgsrc={val.imgsrc}
                            date={val.date}
                            month={val.month}
                            category={val.category}
                            title={val.title}
                            desc={val.desc}
                            time={val.time}
                            comment={val.comment}
                        />
                      })
                    }
                </div>
            </div>
        </section>

        
    </>
  );
}

export default Home;
