import blog1 from "../images/blog-1.jpg";
import blog2 from "../images/blog-2.jpg";
import blog3 from "../images/blog-3.jpg";

const BlogData = [
    {
        imgsrc:blog1,
        date: "27",
        month: "AUG",
        category: "Food",
        title: "Lorem ipsum dolor sit amet.",
        desc: "asperiores dolore explicabo aut excepturi aliquam nam?",
        time: "30 min ago",
        comment: "3 comments",
    },
    {
        imgsrc:blog2,
        date: "15",
        month: "Sep",
        category: "Teaching",
        title: "Lorem ipsum dolor sit amet.",
        desc: "asperiores dolore explicabo aut excepturi aliquam nam?",
        time: "15 min ago",
        comment: "5 comments",
    },
    {
        imgsrc:blog3,
        date: "20",
        month: "Oct",
        category: "Gym",
        title: "Lorem ipsum dolor sit amet.",
        desc: "asperiores dolore explicabo aut excepturi aliquam nam?",
        time: "6 min ago",
        comment: "10 comments",
    },
];

export default BlogData;