import workout from "../images/workout.png";
import nutrition from "../images/nutrition.png";
import motivation from "../images/motivation.png";


const Hcarddata = [
    {
        imgsrc:workout,
        title: "Workout Routines",
        desc: "Research confirm physical routines can all improve health during all stage of life improve",
    },
    {
        imgsrc:nutrition,
        title: "Nutrition Strategies",
        desc: "Good food is a life's great pleasures. And for most, good meals are at the heart of family life and celebrations",
    },
    {
        imgsrc:motivation,
        title: "Support & Motivation",
        desc: "Living a healthy lifestyle is a key component to wellness means taking care of you from inside out.",
    },
];

export default Hcarddata;