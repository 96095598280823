import icon1 from "../images/icon-01.png";
import icon2 from "../images/icon-02.png";
import icon3 from "../images/icon-03.png";
import icon4 from "../images/icon-04.png";

const ServiceData = [
    {
        imgsrc:icon1,
        title: "Healthy daily life",
        desc1: "Movement is key to a healthy life",
        desc2: "Plan your meals everyday",
        desc3: "Have a bed time routine",
    },
    {
        imgsrc:icon2,
        title: "Fitness & performance",
        desc1: "Eat the right food and portion",
        desc2: "Get enough sleep everyday",
        desc3: "Work your full-range of motion",
    },
    {
        imgsrc:icon3,
        title: "Exercise Program",
        desc1: "Enjoy the physically activities you choose",
        desc2: "Mixing up the exercises to keep them interesting",
        desc3: "Exercise is about being healthy and having fun",
    },
    {
        imgsrc:icon4,
        title: "Improving Health",
        desc1: "Exercises can boost your mental health",
        desc2: "Take care your mental, emotional well-being",
        desc3: "Balance all aspects of your life",
    },
];

export default ServiceData;