import React from 'react';
import { NavLink } from 'react-router-dom';

const BlogCard = (props) => {
    return(
        <>
            <div className="col-lg-4">
                <div className="blog-block">
                    <div className="blog-image">
                        <img src={props.imgsrc} className="img-fluid" alt />
                        <div className="date">
                            <h6>{props.date}</h6>
                            <h5>{props.month}</h5>
                        </div>
                        <div className="category">
                            <NavLink to=""><h6>{props.category}</h6></NavLink>
                        </div>
                    </div>
                    <div className="blog-title-decs">
                        <h1><NavLink to="">{props.title}</NavLink></h1>
                        <p>{props.desc}</p>
                    </div>
                    <div className="blog-post-comment">
                        <h6 className="post-time"><i class="fa fa-clock-o"></i> {props.time}</h6>
                        <h6 className="post-comment"><i class="fa fa-comment-o"></i> {props.comment}</h6>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BlogCard;