import React from "react";

const Courses = () => {
  return (
    <div className="Header">
      <h1>Welcome home courses</h1>
    </div>
  );
}

export default Courses;
