import React, { Component } from "react";
import darklogo from "../images/dark-logo.png";
import { NavLink } from "react-router-dom";
import $ from "jquery";

class Header extends Component{

    componentDidMount = () =>{

        /*---sticky header---*/
        $(window).scroll(function() {    
            var height = $(window).scrollTop(); 
            if(height  > 100) {
                $(".sticky-header").addClass("is-sticky");
            } else{
                $(".sticky-header").removeClass("is-sticky");
            }

            $('.header-mobile-menu-toggle').click(function(){
                $('.mobile-menu').addClass('show')
            });
            $('.menu-close').click(function(){
                $('.mobile-menu').removeClass('show');
            });
          
        });
    };


    render(){
        return (
            <div className="header-section header-fluid sticky-header section">
                <div className="header-inner">
                    <div class="container position-relative">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-xl-3 col-auto">
                                <div className="header-logo">
                                    <NavLink to="index.html">
                                        <img className="dark-logo" src={darklogo} alt="Learts Logo" />
                                    </NavLink>
                                </div>
                            </div>
        
                            <div className="col d-none d-xl-block position-static mobile-menu">
                                <nav className="site-main-menu">
                                    <ul>
                                        <li>
                                            <NavLink to="" className="menu-close"><i className="fa fa-close"></i></NavLink>
                                        </li>
                                        <li className="position-static">
                                            <NavLink to="/"><span className="menu-text">Home</span></NavLink>
                                        </li>
                                        <li className="">
                                            <NavLink to="about"><span className="menu-text">About</span></NavLink>
                                        </li>
                                        <li className="has-children">
                                            <NavLink to="#"><span className="menu-text">Services</span></NavLink>
                                            <span className="menu-toggle"><i className="far fa-angle-down"></i></span>
                                            <ul className="sub-menu">
                                                <li><NavLink to=""><span className="menu-text">Membership Levels</span></NavLink></li>
                                                <li><NavLink to=""><span className="menu-text">Become a Teacher</span></NavLink></li>
                                            </ul>
                                        </li>
                                        <li className="">
                                            <NavLink to="#"><span className="menu-text">Blog</span></NavLink>
                                            <span className="menu-toggle"></span>
                                        </li>
                                        <li className="">
                                            <NavLink to="contact"><span className="menu-text">Contact Us</span></NavLink>
                                            <span className="menu-toggle"></span>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
        
                            <div className="col-xl-3 col-auto">
                                <div className="header-right">
                                    <div className="inner">
                                        <div className="header-search">
                                            <button className="header-search-toggle"><i className="fa fa-search"></i></button>
                                            <div className="header-search-form">
                                                <form action="#">
                                                    <input type="text" placeholder="Search..." />
                                                    <button><i className="fa fa-search"></i></button>
                                                </form>
                                            </div>
                                        </div>
        
                                        <div className="header-mobile-menu-toggle d-xl-none ml-sm-2">
                                            <button className="toggle">
                                                <i className="icon-top"></i>
                                                <i className="icon-middle"></i>
                                                <i className="icon-bottom"></i>
                                            </button>
                                        </div>
        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          );
    };
};

export default Header;
