import React from "react";

const Healthcard = (props) => {
    return(
        <>
            <div className="col-lg-4 pl-0 pr-0">
                <div className="health-icon-box text-center">
                    <div className="icon">
                        <img src={props.imgsrc} alt="workout" />
                    </div>
                    <div className="content">
                        <h3 className="title">{props.title}</h3>
                        <div className="desc">
                            <p>{props.desc}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Healthcard;