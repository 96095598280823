import React from "react";
import { NavLink } from "react-router-dom";

const Footer = () => {
    return(
        <div className="footer-section">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-md-5 col-12 max-mb-50">
                        <div className="footer-widget">
                            <h4 className="footer-widget-title">Address</h4>
                            <div className="footer-widget-content">
                                <div className="content">
                                    <p>382 NE 191st St # 87394 Miami, FL 33179-3899</p>
                                    <p>+1 (305) 547-9909 (9am - 5pm EST, Monday - Friday) </p>
                                    <p><NavLink to="#">info@example.com </NavLink></p>
                                </div>
                                <div className="footer-social-inline">
                                    <NavLink to="#"><i class="fa fa-facebook-square"></i></NavLink>
                                    <NavLink to="#"><i class="fa fa-twitter"></i></NavLink>
                                    <NavLink to="#"><i class="fa fa-instagram"></i></NavLink>
                                    <NavLink to="#"><i class="fa fa-linkedin"></i></NavLink>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-3 col-md-4 col-sm-7 col-12 max-mb-50">
                        <div className="footer-widget">
                            <h4 className="footer-widget-title">Explore</h4>
                            <div className="footer-widget-content">
                                <ul className="column-2">
                                    <li><NavLink to="#">Start here</NavLink></li>
                                    <li><NavLink to="#">Success story</NavLink></li>
                                    <li><NavLink to="#">Blog</NavLink></li>
                                    <li><NavLink to="#">Courses</NavLink></li>
                                    <li><NavLink to="#">About us</NavLink></li>
                                    <li><NavLink to="#">Contact us</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-3 col-md-3 col-sm-5 col-12 max-mb-50">
                        <div className="footer-widget">
                            <h4 className="footer-widget-title">Information</h4>
                            <div className="footer-widget-content">
                                <ul>
                                    <li><NavLink to="#">Membership</NavLink></li>
                                    <li><NavLink to="#">Purchase guide</NavLink></li>
                                    <li><NavLink to="#">Privacy policy</NavLink></li>
                                    <li><NavLink to="#">Terms of service</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col text-center">
                        <p className="copyright">Copyright © 2021 Maxcoach. All Rights Reserved | Designed & Developed by : <a href="https://dreamofdesign.in" target="_blank">dreamofdesign</a></p>
                    </div>
                </div>
            </div>
        </div>
    );     
}

export default Footer;