import React from "react";

const ServiceCard = (props) => {
    return(
        <>
            <div className="col-lg-6 col-md-6">
                <div className="single-health-service mt-5">
                    <div className="icon">
                        <img src={props.imgsrc} alt="" />
                    </div>
                    <div className="content">
                        <h3 className="title">{props.title}</h3>
                        <div className="description">
                            <ul>
                                <li>{props.desc1}</li>
                                <li>{props.desc2}</li>
                                <li>{props.desc3}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ServiceCard;