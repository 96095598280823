import React from "react";
import about from "./images/about-image.png"

const About = () => {
  return (
    <>
      <section className="page-banner-section">
          <div className="container">
              <div className="row">
                  <div className="col-12">
                      <div className="page-banner-title">
                          <h1 className="title">Grow strong to take up the challenges of life. The top lessons for getting you back on track.</h1>
                      </div>
                  </div>
              </div>
          </div>
      </section>

      <section className="about-section">
        <div className="container">
            <div className="row row-cols-lg-2 row-cols-1 align-items-center">
                <div className="col">
                    <div className="faq-left-image">
                        <img src={about} alt="about" />
                    </div>
                </div>
                <div className="col">
                    <div className="faq-content-area">
                        <div className="section-title">
                            <span className="sub-title">GET TO KNOW ABOUT <strong>MaxCoach</strong></span>
                            <h2 className="title">Learn about our <span>Work Culture</span> at MaxCoach</h2>
                            <p>Spend some time to visit our website or head office and discover our current courses, enrollment procedure, and registration deadline. We're opening new classes every beginning of each month. </p>
                            <p>Spend some time to visit our website or head office and discover our current courses, enrollment procedure, and registration deadline. We're opening new classes every beginning of each month. </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="section-bottom-shape d-md-block d-none">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none" height="100">
                <path className="elementor-shape-fill" d="M 0 0 L0 100 L100 100 L100 0 Q 50 200 0 0"></path>
            </svg>
        </div>
      </section>
    </>
  );
}

export default About;
